h1 {
    font-weight: bolder;
    font-size: x-large;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 10px;
    color: white;
}

.elektryk {
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    margin-right: 60px;
    margin-left: 60px;
    border-radius: 50px;
    border: 1px solid white;
    box-shadow: 0px 0px 30px white;
}

.info-elektryk {
    width: 50%;
    height: auto;
    margin-left: 50px;
    font-size: large;
    text-align: center;
}

#elektrycy {
    font-family: sans-serif;
}

.informatyk {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 100px;
    border: 1px solid white;
    box-shadow: 0px 0px 30px white;
    border-radius: 50px;
    margin-right: 60px;
    margin-left: 60px;
    padding: 30px;
    padding-right: 60px;
}


.info-informatyk {
    margin-left: 10%;
    margin-right: 10%;
    width: 50%;
    height: auto;
    font-size: large;
    text-align: center;
}

.logistyk {
    padding: 30px;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 100px;
    border: 1px solid white;
    box-shadow: 0px 0px 30px white;
    border-radius: 50px;
    margin-bottom: 60px;
    margin-left: 60px;
}

.info-logistyk {
    margin-right: 10%;
    margin-left: 5%;
    font-size: large;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media (max-width: 800px) { 
    .work-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .elektryk {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }
    .informatyk {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .logistyk {
        margin-right: 60px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .info-informatyk {
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .info-elektryk {
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .info-logistyk {
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }
}
