.travel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  color: white;
}

.travelToGreece-description {
  width: 60%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

#travelToGreece {
  font-size: 30px;
  padding: 2px;
}

#travel-description {
  border-top: lightgrey solid 1px;
  padding-top: 7px;
  margin-bottom: 20px;
  color: white;
}

.salonikiToGreece-description {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  text-align: center;
}

.img-doSalonik {
  display: flex;
  justify-content: center;
  width: auto;
  height: 300px;
}

.img-wrazenia {
  display: flex;
  justify-content: center;
}


@media (max-width: 800px) {
  .travelToGreece-description {
    width: 90%;
  }
  .salonikiToGreece-description {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }

  .travel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-wrazenia {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
