.wolne-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
}

#free-time {
    margin-bottom: 10px;
}

#img-poziome {
    width: 350px;
    height: auto;
    padding: 20px;
}

.siema1234 {
width: 50%;
text-align: center;
}

#galeria-wolne {
        font-weight: bold;
        font-size: 15px;
        color: white;
        font-style: italic;
}

@media (max-width: 800px) { 
    .siema1234 {
        width: 90%;
    }
}