#icon-home {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    font-size: 1.4rem;
    border: 1px black;
}
