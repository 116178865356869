* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #000000 !important;
  padding: 5px;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-wrapper > div > img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.grid-wrapper {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}

.grid-wrapper .tall {
  grid-row: span 2;
}

.grid-wrapper .wide {
  grid-column: span 2;
}

.grid-wrapper .main-wide {
  grid-column: 1 / -6;
}

.main-wide {
  position: relative;
}

.tall {
  position: relative;
}

.wide {
  position: relative;
}
.container {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.6s ease;
}

.main-wide:hover .overlay {
  height: 100%;
  opacity: 0.7;
  border-radius: 5px;
}

.wide:hover .overlay {
  height: 100%;
  opacity: 0.7;
  border-radius: 5px;
}

.container:hover .overlay {
  height: 100%;
  opacity: 0.7;
  border-radius: 5px;
}

.tall:hover .overlay {
  height: 100%;
  opacity: 0.7;
  border-radius: 5px;
}

.main-wide:hover img {
  filter: blur(4px);
  transition: 1.5s;
}

.wide:hover img {
  filter: blur(4px);
  transition: 1.5s;
}

.container:hover img {
  filter: blur(4px);
  transition: 1.5s;
}
.tall:hover img {
  filter: blur(4px);
  transition: 1.5s;
}

@media (min-width: 800px) {
  .main-wide h5 {
    font-weight: 900;
    font-size: 30px;
    top: 30%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .main-wide h6 {
    font-size: 15px;
    margin-top: 25%;
    color: white;
    font-style: italic;
    text-align: center;
  }

  .tall h5 {
    font-weight: 900;
    font-size: 30px;
    top: 20%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .tall h6 {
    font-size: 15px;
    line-height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    position: absolute;
    top: 32%;
    color: white;
    font-style: italic;
    text-align: center;
  }

  .container h5 {
    font-weight: 900;
    font-size: 30px;
    top: 20%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .container h6 {
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: 38%;
    margin-right: 10px;
    margin-left: 15px;
    color: white;
    font-style: italic;
    text-align: center;
  }

  .wide h5 {
    font-weight: 900;
    font-size: 30px;
    top: 20%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .wide h6 {
    font-size: 15px;
    line-height: 20px;
    margin-top: 80px;
    color: white;
    font-style: italic;
    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

@media (max-width: 800px) {
  .main-wide h5 {
    font-weight: 900;
    font-size: 30px;
    top: 30%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .main-wide h6 {
    font-size: 15px;
    margin-top: 25%;
    color: white;
    font-style: italic;
    text-align: center;
  }

  .tall h5 {
    font-weight: 900;
    font-size: 9px;
    top: 20%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .tall h6 {
    font-size: 7px;
    line-height: 9px;
    margin-right: 2px;
    margin-left: 2px;
    position: absolute;
    top: 32%;
    color: white;
    font-style: italic;
    text-align: center;
  }

  .container h5 {
    font-weight: 900;
    font-size: 10px;
    top: 20%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .container h6 {
    font-size: 6px;
    line-height: 7px;
    position: absolute;
    top: 38%;
    color: white;
    margin-left: 2px;
    margin-right: 2px;
    font-style: italic;
    text-align: center;
  }

  .wide h5 {
    font-weight: 900;
    font-size: 13px;
    top: 12%;
    width: 100%;
    color: white;
    position: absolute;
    text-align: center;
  }

  .wide h6 {
    font-size: 8px;
    line-height: 10px;
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-style: italic;
    text-align: center;
  }

  .grid-wrapper {
    display: grid;
    grid-auto-rows: 80px;
  }
 
}
