.gmnoprint gm-style-cu {
    display: none !important;
}

.city-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.city-description {
    width: 50%;
    margin-left: 40px;
    margin-right: 40px;
}

#pori {
    font-size: 30px;
    margin-top: -50px;
    margin-bottom: 10px;
}

#img-miasto {
    width: 400px;
    height: 250px;
    margin: 30px 35px;
    padding: 20px;
}

@media (max-width: 800px) {
    .city-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    iframe {
        width: 300px !important;
        height: auto !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .place-card {
        display: none !important;
    }

    #img-miasto {
        width: 300px;
        height: 200px;
        margin: 0px;
    }

    .city-description {
        width: 80%;
    }

    .img-miasto-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}