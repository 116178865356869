.edesa-slide-container {
  width: 50%;
  margin-left: 40px;
  margin-right: 40px;
}

#slider {
  width: 100%;
  border-radius: 50px;
  height: auto;
}

.react-slideshow-container .default-nav {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 800px) { 
  .edesa-slide-container {
    margin-top: 20px;
    width: 100%;
  }
}  