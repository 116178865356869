.larisa {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 50px;
}


.larisa2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 50px;
}

.description-larisa {
    display: flex;
    flex-direction: column;
}

#galeria {
    margin-top: -10px;
    font-weight: bold;
    font-size: 15px;
    color: white;
    font-style: italic;
}

@media (max-width: 800px) { 
    .larisa {
        display: flex;
        flex-direction: column;
    }

    .larisa2 {
        display: flex;
        flex-direction: column-reverse;
    }

}