.slide-container {
  height: auto;
  padding-top: 10px;
  width: 50%;
}

#siema  {
  width: 100%;
}

.react-slideshow-container .default-nav {
  margin-left: 10px;
  margin-right: 10px;
}