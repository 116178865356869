#map-container {
  margin-left: 10px;
  width: 60%;
  padding-top: 10px;
}

.map {
  width: 100%;
  height: 100%;
}
.leaflet-container .leaflet-control-attribution {
  display: none;
}
@media (min-width: 800px) {
  .leaflet-touch .leaflet-bar a {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px;
  }

.leaflet-popup-content {
  margin: 5px 24px 5px 20px !important;
}
}

@media (max-width: 800px) {
  .leaflet-touch .leaflet-bar a {
    width: 15px !important;
    height: 15px !important;
    line-height: 15px !important;
  }
.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
    font-size: 10px !important;
}
.leaflet-popup-content {
    margin: 2px 27px 3px 20px;
    font-size: 13px;
    font-size: 1.08333em;
    min-height: 1px;
}
.leaflet-popup-content-wrapper {
    text-align: center !important;
}

svg {
    width: 10px !important;
    height: 10px !important;
}
.react-slideshow-container .default-nav {
    width: 15px;
    height: 15px;
}
}
