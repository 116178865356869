#project-header {
    font-weight: bolder;
    font-size: 40px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;    
}

.poster {
    display: flex;
    justify-content: center;
}