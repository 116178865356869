* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffff;
}

footer {
  background-color: black;
  height: auto;
  width: 100%;
  font-family: sans-serif;
  color: #ffff;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#kropka {
  font-weight: bolder;
  font-size: 18px;
}

@media (min-width: 800px) {
    #social {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
    margin-top: -2px;
    }

  .footer-content h1 {
    margin-top: 5px;
    font-size: 1.6rem;
    font-weight: bolder;
    text-transform: capitalize;
    line-height: 3rem;
    margin-bottom: -8px;
  }

  .list-links {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  #projects {
    max-width: 500px;
    margin: 5px 8px;
    line-height: 20px;
    font-size: 14px;
  }
}

@media (max-width: 800px) {
    #social {
    width: 13px;
    height: auto;
    margin-top: -17px;
    }

  .footer-content h1 {
    margin-top: -10px;
    font-size: 10px;
    font-weight: bolder;
    text-transform: capitalize;
    line-height: 3rem;
  }

  .list-links {
    margin-top: -25px;
  }

  #projects {
    max-width: 500px;
    margin: 5px 2px;
    line-height: 20px;
    font-size: 6px;
  }

  footer {
    width: 100%;
    height: 50px;
  }
}
