.naglowek {
  font-size: 25px;
  font-weight: bolder;
  font-style: italic;
  border-bottom: 1px solid lightgray;
  padding: 5px;
  color: white;
}

#naglowek {
  padding-bottom: 5px;
}

p {
  color: white
}
.galeria-wrapper {
  margin-top: 20px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(4, 2fr);
  grid-auto-rows: auto;
  grid-auto-flow: dense;
}

.galeria-wrapper .wysoki {
  grid-row: span 2;
}

.galeria-wrapper .szeroki {
  grid-column: span 2;
}

.main-container {
  margin-top: 30px;
  margin-left: 10%;
  margin-right: 10%;
}

@media (max-width: 800px) {
  #pion {
    height: auto;
    width: 100%;
  }

  #pion1 {
    height: auto;
    width: 100%;
  }

  #szer {
    width: 70%;
    height: auto;
  }

  .main-container {
    margin-left: 2%;
    margin-right: 10%;
  }
}
