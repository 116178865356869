.info1 {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    margin-right: 60px;
    margin-left: 60px;
    border-radius: 50px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 30px lightgray;
}

#speek {
    width: 60px;
    height: auto;
}

.opinie {
    display: flex;
    flex: row;
    margin-left: 20px;
    margin-right: 50px;
}

#siemandero {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
}

@media (max-width: 800px) { 
    .info1 {
        margin-left: 10px;
        margin-right: 10px;
        width: 90%;
    }
}