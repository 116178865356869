.log-slide-container {
  width: 25%;
  margin-left: 100px;
  margin-right: 40px;
}

#slider {
  width: 100%;
  border-radius: 50px;
}

.react-slideshow-container .default-nav {
  margin-left: 10px;
  margin-right: 10px;
}


@media (max-width: 800px) { 
  .log-slide-container {
    width: 80%;
    margin-left: 40px !important;
  }
}