#img-mieszkanie {
    width: 400px;
    height: 200px;
    padding: 20px;
}

#img-jedzenie {
    width: 350px;
    height: 200px;
    padding: 20px;
}

#img-jedzenie2 {
    width: 25%;
    height: auto;
    padding: 20px;
}

.zakwaterowanie-wrapper {
    margin-top: 20px;
    margin-left: 100px;
    display: flex;
    margin-right: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#hotel {
    font-size: 40px;
}

.zakwaterowanie-description {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.jedzenie-hotel {
    margin-top: 50px;
}

.hotel {
    margin-top: 30px;
}

@media (max-width: 800px) { 
    .zakwaterowanie-description {
        font-size: 15px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .zakwaterowanie-wrapper {
        margin: 0;
    }

    .img-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #img-jedzenie2 {
        width: 60%;
    }
}